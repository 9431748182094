<template>
    <b-container fluid class="contact">
        <b-container class="banner">
            <img
                src="~@/assets/images/contact/baner.png"
                class="banner-img"
                alt=""
            />
            <div class="search-bar">
                <div class="title">{{ $t('contact') }}</div>
                <div class="title">
                    {{ $t('forEnglishContact') }}
                </div>
            </div>
        </b-container>
        <b-container class="contact-content-text" fluid>
            <b-container>
                <b-row class="contact-content-main">
                    <b-col md="12" lg="7">
                        <div class="main-item">
                            <h3>
                                1.{{ $t('enterpriseServiceManagement') }}
                            </h3>
                            <p>
                                10 ANSON ROAD # 13 - 09 INTERNATIONAL PLAZA
                                SINGAPORE
                            </p>
                            <p>
                                {{ $t('fieldWork') }}
                            </p>
                            <p>{{ $t('companyNumber') }} <span> 202203414Z</span></p>
                        </div>
                        <div class="main-item">
                            <h3>2.{{ $t('fundJoint') }}</h3>
                            <p class="text-uppercase">
                                <img
                                    src="~@/assets/images/contact/map.png"
                                    alt=""
                                />
                                {{ $t('representativeEnterpriseAddress') }}
                            </p>
                            <p>
                                <img
                                    src="~@/assets/images/contact/phone.png"
                                    alt=""
                                />
                                <a href="tel:+84835129999">
                                    (+84) 835.12.9999
                                </a>
                            </p>
                            <p>
                                <img
                                    src="~@/assets/images/contact/phone.png"
                                    alt=""
                                />
                                <a href="tel:+84942307041">
                                   FOR ENGLISH, PLEASE CONTACT: 0942 307 041
                                </a>
                            </p>
                            <p>
                                <img
                                    src="~@/assets/images/contact/global.png"
                                    alt=""
                                />
                                <a href="mailto:support@pifund.io"
                                    >SUPPORT@PIFUND.IO</a
                                >
                            </p>
                        </div>
                    </b-col>
                    <b-col md="12" lg="5">
                        <b-form
                            class="form-contact"
                            @submit.prevent="onSendContact"
                        >
                            <b-input
                                type="text"
                                :placeholder="$t('name')"
                                required
                                v-model="formContact.fullname"
                            ></b-input>
                            <b-input
                                type="email"
                                placeholder="Email"
                                required
                                v-model="formContact.email"
                            ></b-input>
                            <b-form-group>
                                <b-form-radio
                                    required
                                    v-model="formContact.type"
                                    :aria-describedby="ariaDescribedby"
                                    name="some-radios"
                                    :value="1"
                                    > {{ $t('investors') }}</b-form-radio
                                >
                                <b-form-radio
                                    required
                                    v-model="formContact.type"
                                    :aria-describedby="ariaDescribedby"
                                    name="some-radios"
                                    :value="2"
                                    > {{ $t('personalBusiness') }}</b-form-radio
                                >
                            </b-form-group>
                            <b-input
                                type="phone"
                                :placeholder="$t('phone')"
                                required
                                v-model="formContact.phone"
                            ></b-input>
                            <b-form-textarea
                                id="textarea-rows"
                                rows="5"
                                v-model="formContact.description"
                            ></b-form-textarea>
                            <div class="custom-btn">
                                <b-button type="submit"> {{ $t('send') }}</b-button>
                            </div>
                        </b-form>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <b-container class="contact-content-text2">
            <b-row class="contact-content-main2">
                <b-col lg="6">
                    <div class="contact-phone">
                        <h3 class="text-uppercase"> {{ $t('contact') }} {{ $t('hotline') }} 24/7</h3>
                        <h5>
                            {{ $t('callDirectly') }}
                        </h5>
                        <a href="tel:+84835129999" class="d-block">
                            <b-button> (+84) 835.12.9999 </b-button>
                        </a>
                    </div>
                </b-col>
                <b-col lg="6">
                    <div class="contact-chat">
                        <h3> {{ $t('onlineChat') }}</h3>
                        <h5>
                            {{ $t('clickChatOnline') }}
                        </h5>
                        <a href="#" @click.prevent="$tawkMessenger.toggle()">
                            <b-button> {{ $t('liveChat') }}</b-button>
                        </a>
                    </div>
                </b-col>
            </b-row>
            <b-row class="contact-content-map">
                <b-col lg="12">
                    <div class="item-carousel">
                        <div class="event-item">
                            <div class="event-img">
                                <!-- <img
                                    src="~@/assets/images/contact/map-img.png"
                                    alt=""
                                /> -->
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.332955887063!2d106.66518535057955!3d10.78579076193195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752ed22b5f8907%3A0x54b69beb3a9daeea!2zNTM4IEPDoWNoIE3huqFuZyBUaMOhbmcgOCwgUGjGsOG7nW5nIDExLCBRdeG6rW4gMywgVGjDoG5oIHBo4buRIEjhu5MgQ2jDrSBNaW5oLCBWaeG7h3QgTmFt!5e0!3m2!1svi!2s!4v1647000225339!5m2!1svi!2s"
                                    width="100%"
                                    height="100%"
                                    style="border: 0"
                                    allowfullscreen=""
                                    loading="lazy"
                                ></iframe>
                            </div>
                            <div class="event-content">
                                <h4> {{ $t('officeAdress') }}</h4>
                                <p>
                                    {{ $t('piCompanyAddress') }}
                                </p>
                                <b-button> {{ $t('findWay') }}</b-button>
                            </div>
                            <div class="box-sub-bg"></div>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </b-container>
</template>

<script>
export default {
    data() {
        return {
            showSearch: false,
            page: 1,
            listPage: 3,
            selected: '',
            formContact: {
                fullname: '',
                email: '',
                type: 1,
                phone: '',
                description: '',
            },
        };
    },
    methods: {
        onSendContact() {
            this.$store.dispatch(
                'contact/req_postSendContract',
                this.formContact,
            );
        },
    },
    created() {
        this.unsubscribe = this.$store.subscribe((mutation) => {
            switch (mutation.type) {
                case 'core/SUCCESS_MESSAGE':
                    this.formContact = {
                        fullname: '',
                        email: '',
                        type: 1,
                        phone: '',
                        description: '',
                    };
                    break;
                default:
                    break;
            }
        });
    },
    beforeDestroy() {
        this.unsubscribe();
    },
};
</script>

<style lang="scss">
.contact {
    padding: 50px 0;
    .banner {
        position: relative;
        min-height: 300px;
        border-radius: 25px;
        &.container {
            @media (max-width: 575px) {
                max-width: calc(100% - 30px);
            }
            @media (min-width: 1200px) {
                max-width: 1200px;
            }
            @media (min-width: 1500px) {
                max-width: 1400px;
            }
        }
        img.banner-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 25px;
        }
        .search-bar {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            height: max-content;
            width: max-content;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .title {
                font-family: 'Roboto', sans-serif;
                color: #fff;
                font-size: 40px;
                font-weight: 600;
                text-align: center;
                text-transform: uppercase;
                margin-bottom: 0px;
                @media (max-width: 991px) {
                    font-size: 30px;
                }
                @media (max-width: 767px) {
                    font-size: 26px;
                }
            }
        }
    }
    .contact-content-text {
        background-color: #f7f7f7;
        margin: 50px 0px;
        padding: 30px 0px;
        .contact-content-main {
            .main-item {
                h3 {
                    color: #003189;
                    font-weight: 800;
                    @media (max-width: 575px) {
                        font-size: 20px;
                    }
                }
                p {
                    font-weight: 500;
                    margin-bottom: 10px;
                    display: flex;
                    align-items: flex-start;
                    @media (max-width: 575px) {
                        font-size: 14px;
                        align-items: center;
                    }
                    img {
                        height: 100%;
                        width: auto;
                        margin-right: 10px;
                    }
                    span {
                        font-weight: 800;
                        margin-left: 5px;
                    }
                    a {
                        color: #000000;
                    }
                }
            }
            .form-contact {
                width: 100%;
                max-width: 350px;
                float: right;
                @media (max-width: 991px) {
                    max-width: inherit;
                    float: none;
                    margin-top: 30px;
                }
                .form-control {
                    border-radius: 25px;
                    margin-bottom: 5px;
                    box-shadow: 2px 2px 5px #00000037;
                    &::placeholder {
                        padding-left: 20px;
                        font-style: italic;
                        font-size: 14px;
                        font-weight: 500;
                    }
                }
                textarea {
                    border-radius: 15px !important;
                }
                .form-group {
                    margin: 15px 0px;
                    div {
                        display: flex;
                        justify-content: space-between;
                    }
                    .bv-no-focus-ring {
                        display: flex;
                        justify-content: space-around;
                        .custom-control-inline {
                            margin-right: 0px;
                            label {
                                font-weight: bold;
                                &::before {
                                    width: 15px !important;
                                    height: 15px !important;
                                    border-radius: 50% !important;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    border: 1px solid #000093;
                                    padding: 2px;
                                }
                                &::after {
                                    top: 0.35rem;
                                    left: -1.4rem;
                                    background: none;
                                }
                            }
                        }
                    }
                }
                .custom-btn {
                    @media (max-width: 991px) {
                        width: 100%;
                        text-align: center;
                    }
                    button {
                        margin-top: 10px;
                        background-color: #000093;
                        border-radius: 20px;
                        width: 100%;
                        max-width: 175px;
                        font-weight: bold;
                        margin-left: 20px;
                    }
                }
            }
        }
    }
    .contact-content-text2 {
        .contact-content-main2 {
            .contact-phone {
                width: 100%;
                height: 300px;
                background-image: url('~@/assets/images/contact/bg-phone.png');
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 100% 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-content: center;
                color: #fff;
                padding: 5px;
                h3 {
                    text-align: center;
                    font-weight: 700;
                    @media (max-width: 575px) {
                        font-size: 20px;
                    }
                }
                h5 {
                    width: 100%;
                    max-width: 450px;
                    margin: 0 auto;
                    font-size: 16px;
                    line-height: 30px;
                    margin-bottom: 10px;
                    text-align: center;
                    @media (max-width: 575px) {
                        font-size: 13px;
                    }
                }
                a {
                    margin: 0 auto;
                    width: 100%;
                    max-width: 175px;
                    button {
                        border-radius: 15px;
                        background-image: linear-gradient(
                            to right,
                            #237fff,
                            #0930ff
                        );
                        border: 2px solid #fff;
                        font-weight: 700;
                        width: 100%;
                    }
                }
            }
            .contact-chat {
                width: 100%;
                height: 300px;
                background-image: url('~@/assets/images/contact/bg-chat.png');
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 100% 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-content: center;
                color: #fff;
                padding: 5px;
                @media (max-width: 991px) {
                    margin: 30px 0px;
                }
                h3 {
                    text-align: center;
                    font-weight: 700;
                    @media (max-width: 575px) {
                        font-size: 20px;
                    }
                }
                h5 {
                    width: 100%;
                    max-width: 450px;
                    margin: 0 auto;
                    font-size: 16px;
                    line-height: 30px;
                    margin-bottom: 10px;
                    text-align: center;
                    @media (max-width: 575px) {
                        font-size: 13px;
                    }
                }
                a {
                    margin: 0 auto;
                    width: 100%;
                    max-width: 200px;
                    button {
                        border-radius: 15px;
                        background-image: linear-gradient(
                            to right,
                            #237fff,
                            #0930ff
                        );
                        border: 2px solid #fff;
                        font-weight: 700;
                        width: 100%;
                    }
                }
            }
        }
        .contact-content-map {
            .item-carousel {
                padding: 50px 0px 20px 0px;
                @media (max-width: 767px) {
                    padding: 0px 0px 20px 0px;
                    margin-top: 20px;
                }
                .event-item {
                    width: 100%;
                    height: 100%;
                    min-height: 350px;
                    color: #ffffff;
                    position: relative;
                    overflow: hidden;
                    z-index: 1;
                    .event-img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                        }
                    }
                    .event-content {
                        position: absolute;
                        bottom: 0;
                        padding: 0px 0px 20px 50px;
                        @media (max-width: 575px) {
                            padding: 20px;
                        }
                        h4 {
                            margin-bottom: 5px;
                            font-size: 29px;
                            font-weight: 800;
                            position: relative;
                            z-index: 2;
                            @media (max-width: 1199px) {
                                font-size: 25px;
                            }
                        }
                        p {
                            display: none;
                            margin-bottom: 10px;
                            font-size: 17px;
                            position: relative;
                            z-index: 2;
                            display: -webkit-box;
                            line-height: 25px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            font-weight: 700;
                            @media (max-width: 1199px) {
                                font-size: 15px;
                            }
                        }
                        button {
                            border-radius: 15px;
                            background-image: linear-gradient(
                                to right,
                                #237fff,
                                #0930ff
                            );
                            border: 2px solid #fff;
                            font-weight: 700;
                            width: 100%;
                            max-width: 200px;
                            position: relative;
                            z-index: 2;
                        }
                    }
                    .box-sub-bg {
                        position: absolute;
                        bottom: 0px;
                        left: 0px;
                        width: 100%;
                        height: 100%;
                        max-height: 120px;
                        background-image: linear-gradient(
                            to bottom,
                            #003189,
                            #000000
                        );
                        opacity: 0.75;
                        box-shadow: -5px -30px 30px #003089;
                        display: flex;
                        align-items: flex-end;
                    }
                }
            }
        }
    }
}
.custom-control-input:checked ~ .custom-control-label::before {
    background-color: #000093;
}
</style>
